import { getSessionData } from './schibsted-account/index.server.js';

export const initLoginSuccess = (): void => {
  const selectors = {
    LOGIN_SUCCESS_EXPERIMENT: '#login-success',
    USER_FULLNAME: '.login-success__user-fullname',
    MAIN_HEADER_SUBSCRIBE_BTN: '.ch-subscribe-btn-container',
    MAIN_HEADER_HAMBURGER: '.ch-menu-icon',
    MAIN_HEADER_USERNAME: 'a.ch-user-name',
    MAIN_MENU_HAMBURGER: '.ch-menu-state-label',
    MAIN_MENU_USERNAME: '.ch-login-info',
    MAIN_MENU_MIN_SIDE: '.ch-my-site-link',
    LOGGED_IN: 'imp-logged-in',
    LOGGED_OUT: 'imp-no-session',
    MIN_SIDE_LINK: 'a.ch-user-name',
  };

  const constants = {
    EXPERIMENT_KEY: 'login-success-experiment',
    NOT_LOGGED_IN_USER_DATA: { 'user-status': 'notLoggedIn' },
    NO_EXPERIMENT_VIEW: 'no-experiment-view',
    EXPERIMENT_VIEW: 'experiment-view',
  };

  let listenersAttached = false;

  function redirectToMinSide() {
    window.location.assign(
      (document.querySelector(selectors.MIN_SIDE_LINK) as HTMLAnchorElement)
        .href,
    );
  }

  const pulseEvents = {
    experimentViews: function () {
      window.pulse(function (tracker) {
        tracker.track('trackerEvent', {
          object: {
            type: 'Content',
            id: 'login-success',
            contentId: 'LoginSuccess',
          },
        });
      });
    },
    minSideClicks: function (element, view) {
      window.pulse(function (tracker) {
        tracker.evaluateEventInputs().then(function (eventDefaults) {
          tracker
            .track('engagementEvent', {
              action: 'Click',
              object: {
                type: 'UIElement',
                id: 'login-success-' + element + '-' + view,
                name: 'LoginSuccess-MinSide',
              },
              target: eventDefaults.object,
              provider: {
                component: 'schibsted-abo',
              },
            })
            .then(function () {
              redirectToMinSide();
            })
            .catch(function () {
              redirectToMinSide();
            });
        });
      });
    },
  };

  const experimentSessionData = {
    read: function () {
      const rawData = window.sessionStorage.getItem(constants.EXPERIMENT_KEY);
      try {
        return JSON.parse(rawData);
      } catch (error) {
        console.log('Error parsing experiment local data: ' + rawData, error);
      }
    },
    set: function (data) {
      try {
        window.sessionStorage.setItem(
          constants.EXPERIMENT_KEY,
          JSON.stringify(data),
        );
      } catch (error) {
        console.log('Error setting experiment expiry local data: ' + error);
      }
    },
    remove: function () {
      window.sessionStorage.removeItem(constants.EXPERIMENT_KEY);
    },
  };

  function calculatePositionByReference(element, reference) {
    // The "20" is the 20px of the arrow icon's width
    element.style.left =
      reference.left + reference.width / 2 - element.offsetWidth + 20 + 'px';
  }

  function showExperiment(user) {
    let referenceDOMElement;

    if (
      (
        document.querySelector(
          selectors.MAIN_HEADER_SUBSCRIBE_BTN,
        ) as HTMLElement
      ).style.display === 'none'
    ) {
      referenceDOMElement = document.querySelector(
        selectors.MAIN_HEADER_USERNAME,
      );
    } else {
      referenceDOMElement = document.querySelector(
        selectors.MAIN_HEADER_HAMBURGER,
      );
    }

    const loginSuccessElement = document.querySelector(
      selectors.LOGIN_SUCCESS_EXPERIMENT,
    );

    loginSuccessElement.classList.add('show');

    const userNameElement = document.querySelector(selectors.USER_FULLNAME);
    const userFullName = user.givenName + ' ' + user.familyName;

    userNameElement.textContent = userFullName;

    const coreHeaderDOMRect = referenceDOMElement.getBoundingClientRect();
    calculatePositionByReference(loginSuccessElement, coreHeaderDOMRect);

    pulseEvents.experimentViews();
  }

  function trackClicksOnMinside(view) {
    document.querySelector(selectors.MAIN_HEADER_USERNAME) &&
      document
        .querySelector(selectors.MAIN_HEADER_USERNAME)
        .addEventListener('click', function (event) {
          event.preventDefault();
          pulseEvents.minSideClicks('username-main-header', view);
        });

    const tryToAddEventListener = function (func, selector, ms) {
      return new Promise(function (resolve, reject) {
        const intervalHandler = setInterval(function () {
          if (document.querySelector(selector)) {
            document.querySelector(selector).addEventListener('click', func);
            clearInterval(intervalHandler);
            clearTimeout(timeoutHandler);
            resolve('resolving');
          }
        }, 100);
        const timeoutHandler = setTimeout(function () {
          clearInterval(intervalHandler);
          reject();
        }, ms);
      });
    };

    let attatchEventsOnceAfterClick = function () {
      attatchEventsOnceAfterClick = function () {
        /* */
      }; // call it only once
      if (!listenersAttached) {
        const addEventListenerUsername = tryToAddEventListener(
          function (event) {
            event.preventDefault();
            pulseEvents.minSideClicks('username-main-menu', view);
          },
          selectors.MAIN_MENU_USERNAME,
          10000,
        );
        const addEventListenerMainSide = tryToAddEventListener(
          function (event) {
            event.preventDefault();
            pulseEvents.minSideClicks('min-side-main-menu', view);
          },
          selectors.MAIN_MENU_MIN_SIDE,
          10000,
        );
        Promise.all([addEventListenerUsername, addEventListenerMainSide])
          .then(function () {
            listenersAttached = true;
          })
          .catch(function () {
            /* */
          });
      }
    };

    document
      .querySelector(selectors.MAIN_MENU_HAMBURGER)
      .addEventListener('click', attatchEventsOnceAfterClick);
  }

  function userJustLoggedIn() {
    if (
      experimentSessionData.read() &&
      experimentSessionData.read()['user-status'] === 'notLoggedIn'
    ) {
      experimentSessionData.remove();

      return true;
    } else {
      return false;
    }
  }

  function findUserInCoreHeader(user) {
    const waitForUserInCoreHeader = setInterval(function () {
      if (
        document.body.classList.contains(selectors.LOGGED_IN) &&
        document.querySelector(selectors.MAIN_HEADER_USERNAME) &&
        document.querySelector(selectors.MAIN_MENU_HAMBURGER)
      ) {
        if (userJustLoggedIn()) {
          showExperiment(user);
          trackClicksOnMinside(constants.EXPERIMENT_VIEW);
        } else {
          trackClicksOnMinside(constants.NO_EXPERIMENT_VIEW);
        }
        clearInterval(waitForUserInCoreHeader);
      }
    }, 100);
  }

  function initLoginSuccessExperiment() {
    getSessionData()
      .then((sessionData) => {
        if (!sessionData) {
          experimentSessionData.set(constants.NOT_LOGGED_IN_USER_DATA);
          trackClicksOnMinside(constants.NO_EXPERIMENT_VIEW);

          return;
        }

        const { user } = sessionData;
        if (user.result) {
          findUserInCoreHeader(user);
        } else {
          trackClicksOnMinside(constants.NO_EXPERIMENT_VIEW);
        }
      })
      .catch(() => {
        experimentSessionData.set(constants.NOT_LOGGED_IN_USER_DATA);
        trackClicksOnMinside(constants.NO_EXPERIMENT_VIEW);
      });
  }
  initLoginSuccessExperiment();
};
