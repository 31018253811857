export const initBuyAboTracking = (trackingName: string = 'buy-abo') => {
  const buyAboStripe = document.querySelector('#buy-abo-stripe-abo');

  if (!buyAboStripe) {
    console.error('buyAboStripe not found');

    return;
  }

  if (!window.pulse) {
    console.error('pulse not found');

    return;
  }

  const buttonId = buyAboStripe.getAttribute('data-button-id');
  const stripeName = `${trackingName}-stripe${buttonId ? `-${buttonId}` : ''}`;
  const buttonName = `${trackingName}-button${buttonId ? `-${buttonId}` : ''}`;
  function trackIfStripeIsInView(
    e: IntersectionObserverEntry[],
    element: Element,
  ): void {
    const observation = e.find((e) => e.target === element);

    if (observation.isIntersecting) {
      trackInview();
    }
  }

  const observer = new IntersectionObserver((entries) =>
    trackIfStripeIsInView(entries, buyAboStripe),
  );

  observer.observe(buyAboStripe);

  const trackInview = () => {
    window.pulse('track', 'viewEvent', {
      type: 'View',
      object: {
        id: stripeName,
        type: 'UIElement',
        elementType: 'Widget',
        name: stripeName,
        contentId: 'Forsiden',
        page: {
          id: 'widget',
          type: 'Frontpage',
        },
      },
      provider: {
        component: 'schibsted-abo',
      },
    });
  };

  const trackBuyAboClick = () => {
    window.pulse('track', 'engagementEvent', {
      action: 'Click',
      object: {
        id: buttonName,
        type: 'UIElement',
        elementType: 'Link',
        name: buttonName,
        page: {
          id: 'widget',
          type: 'Frontpage',
        },
        parent: {
          id: stripeName,
          type: 'UIElement',
          elementType: 'Widget',
        },
      },
    });
  };

  const buyAboButton = buyAboStripe.querySelector('A');
  buyAboButton.addEventListener('click', trackBuyAboClick);
};
