import { reloadPersistedPageAfterMinutes } from '../../core/js/reload-persisted-page.js';
import { initLoginSuccess } from '../../core/js/login-success.js';
import { initBtPulse } from '../js/init-pulse.js';
import { initDarkMode } from '../js/init-dark-mode.js';
import { isUserConsent } from '../../core/js/user-consents.js';
import { initNotifications } from '../js/init-notifications.js';
import { initSetClassSubHeader } from '../../regional/js/init-setclass-subheader.js';
import { initCCHeaderIcon } from '../../regional/js/init-cc-header-icon.js';
import { provideCoreHeaderSessionAdapter } from '../../core/js/schibsted-account/core-header-adapter.js';
import {
  getUserStatus,
  initUserSession,
} from '../../core/js/schibsted-account/index.client.js';
import { userDataStoreApi } from '../../core/js/user-data-store/api.client.js';
import { initBuyAboTracking } from '../../regional/js/buy-abo-tracking.js';
import { initializeBrowserLogs } from '../../core/browser-logs.js';
import { initSourcepoint } from '../../core/js/sourcepoint.js';

initializeBrowserLogs();

window.isUserConsent = isUserConsent;
window.userDataStoreApi = userDataStoreApi;

initUserSession();
initSourcepoint();
provideCoreHeaderSessionAdapter();
reloadPersistedPageAfterMinutes(60);
initLoginSuccess();
initBtPulse();
initDarkMode();
initNotifications();
initSetClassSubHeader();
initCCHeaderIcon();

getUserStatus().then((userStatus) => {
  if (!userStatus.isSubscriber) {
    showBuyAboStripe();
  }
});

window.addEventListener('load', () => {
  Promise.all([
    import('../js-chunks/on-load.js'),
    import('../js-chunks/ads.js'),
    import('../js-chunks/braze.js'),
  ]).catch((e) => console.error(e));
});

const showBuyAboStripe = () => {
  const buyAboStripe = document.getElementById('buy-abo-stripe-abo');
  if (buyAboStripe) {
    initBuyAboTracking('bt-frontpage');
    buyAboStripe.style.display = 'flex';
  }
};
